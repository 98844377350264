import React from 'react'
import {HgButtonLink, type HgButtonLinkProps} from '~/design-system/hg/components'

export type HgComposableDetailsLinkGroupProps = {
  title: React.ReactNode
  links: HgButtonLinkProps[]
  className?: string
}

export const HgComposableDetailsLinkGroup: React.FC<
  HgComposableDetailsLinkGroupProps
> = ({title, links, className}) => {
  return (
    <div className={className}>
      {title && (
        <h3 className="mb-s2 text-text-default arcadia-heading-9">{title}</h3>
      )}
      <div className="flex flex-wrap gap-s2">
        {links.map((linkProps, index) => (
          <HgButtonLink key={index} variant="tonal" {...linkProps} />
        ))}
      </div>
    </div>
  )
}

export default HgComposableDetailsLinkGroup
